.masterValidate {
    background-color: var(--white);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 48px;
    height:100vh;
}


.masterResetPassword {
    background-color: var(--white);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height:100vh;
}

.containerMessage {
    //background-color: var(--red);
    display: flex;
    //flex: 1;
    //justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 48px;
}

.containerEmail {
    //background-color: var(--green);
    //display: flex;
    //flex: 1;
    //justify-content: flex-start;
    //align-items: flex-end;
    padding: 12px;
}

.welcomeText {
    color: var(--primary);
    font-size: 1.5em;
    //font-family: 'Roboto';
}

.validate-logo {
    height: 80px;
}

.validate-header {
    display: flex;
    //flex-direction: column;
    //flex: 1;
    color: white;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 24px;
    padding-top: 24px;
}

.validate-title {
    padding-top: 10px;
    font-size: 0.8em;
}

.containerForm {
    //flex:3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.containerButton {
    //flex:1;
    padding-top: 65px;
    justify-content: center;
    align-items: center;
}

.containerTextfield {
//flex:1;
    padding-top: 10px;
    padding-bottom: 20px;
    justify-content: center;
}

.links-row {
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.links-text {
    font-size: 1.0em;
    color: #999999;
}

.links-text a{
    color: var(--primary);
    text-decoration: none;
}