

.homeMaster {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--white);
    justify-content: center;
    align-items: center;
    height:100vh;
    width: 100%;
}

.home-logo {
//animation: App-logo-spin infinite 20s linear;
    height: 100px;
}

.home-header {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: white;
    justify-content: center;
    align-items: center;
//padding-top: 150px;
//background-color: yellow;
}

.home-title {
    padding-top: 12px;
    font-size: 1.2em;
}

.home-button-row {
    padding-top: 20px;
    min-width: 250px;
//background-color: aqua;
    justify-content: space-between;
}

.home-buttons {
    opacity: 0.3;
    margin: 5px;
    border-radius: 8px;
}

.links-row-home {
    padding-top: 10px;
//background-color: aqua;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.links-text-home {
    font-size: 1.2em;
    color: #999999;
}

.links-text-home a{
    color: #ffffff;
    text-decoration: none;
}

